<template>
	<transition name="slide-fade" mode="out-in">
		<Layout>
			<b-loading :is-full-page="false" v-model="loadingRedirect"></b-loading>
			<ValidationObserver class="auth__form" ref="observer" v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(signIn)">
					<Logo />
					<div class="mb-5 text-center">
						<h1 class="title is-size-3 is-bold m-0">Entrar</h1>
						<!-- prettier-ignore -->
						<p>Não tem conta? <router-link :to="{ name: 'Register' }" class="is-semibold has-text-secondary">Faça seu cadastro!</router-link></p>
					</div>

					<InputWithValidation tab="1" class="mb-5" rules="required|email" type="email" name="email" label="E-mail" v-model="auth.email" />

					<InputWithValidation tab="2" class="mb-5" rules="required|min:8" type="password" name="password" label="Senha" vid="password" password-reveal v-model="auth.password">
						<router-link :to="{ name: 'Forgot' }" class="auth__forgot" tabindex="4">Esqueceu a senha?</router-link>
					</InputWithValidation>

					<span class="is-block text-center" v-if="reCaptcha">
						<vue-recaptcha sitekey="6LdwjmUbAAAAAPoh61kQi64a21H06Y2ocn2YdiRC" :loadRecaptchaScript="true" ref="recaptcha" @verify="onVerify" />
					</span>

					<span class="is-block text-center">
						<b-button tabindex="3" native-type="submit" class="button is-button is-secondary" :loading="loading">Entrar</b-button>
					</span>

					<div v-if="enableSocialLogin && !loadingRedirect">
						<span class="auth__or">
							<span>ou</span>
						</span>

						<span v-for="(s, i) in social" :key="i">
							<b-button native-type="button" class="button is-button  auth__social" :class="s" @click="redirectSocial(s)">
								<svg-icon class="icon" :icon="s"></svg-icon>
								<!-- prettier-ignore -->
								<span>Entrar com o <strong>{{ s }}</strong></span>
							</b-button>
						</span>
					</div>
				</form>
			</ValidationObserver>
		</Layout>
	</transition>
</template>

<script>
import Layout from '@/layouts/Auth'
import Logo from '@/components/Logo'
import Icon from '@/components/Icon'
import eventHub from '@/services/eventHub'
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import { ToastProgrammatic as Toast } from 'buefy'
import VueRecaptcha from 'vue-recaptcha'
import { mapState } from 'vuex'

export default {
	name: 'Login',
	components: {
		Layout,
		Logo,
		InputWithValidation,
		ValidationObserver,
		VueRecaptcha,
		'svg-icon': Icon
	},
	data() {
		return {
			loading: false,
			auth: {
				email: '',
				password: ''
			},
			counter: 0,
			reCaptcha: false,
			reCaptchaSuccess: false,
			social: ['facebook', 'linkedin', 'twitter', 'gmail'],
			enableSocialLogin: process.env.VUE_APP_SOCIAL_LOGIN === 'true' ? true : false
		}
	},
	created() {
		localStorage.removeItem('@podash:user')
		localStorage.removeItem('@podash:permission')
	},
	methods: {
		redirectSocial(driver) {
			if (driver == 'gmail') {
				driver = 'google'
			}

			window.location.href = `${process.env.VUE_APP_BASE_API}auth/${driver}/redirect`
		},
		onVerify(response) {
			if (response) {
				this.reCaptchaSuccess = false
			}
		},
		async signIn() {
			try {
				this.loading = true
				if (this.reCaptchaSuccess && this.reCaptcha) {
					Toast.open({
						message: 'Dados inválidos',
						type: 'is-danger',
						position: 'is-bottom'
					})
					this.$refs.recaptcha.reset()
					return
				}
				const response = await Api.post('user/authenticate', this.auth)
				const { status } = response
				if (status === 200) {
					const { token } = response.data
					localStorage.setItem('@podash:token', token)
					await this.$router.push('dashboard')
					eventHub.$emit('animate-dashboard')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					Toast.open({
						message,
						type: 'is-danger',
						position: 'is-bottom'
					})
					if (this.reCaptcha) {
						this.$refs.recaptcha.reset()
					} else {
						this.counter++
						if (this.counter > 0) {
							this.reCaptcha = true
							this.reCaptchaSuccess = true
						}
					}
				}
			} finally {
				this.loading = false
			}
		}
	},
	computed: {
		...mapState('user', [
			'loadingRedirect'
		])
	}
}
</script>
